.geocoder-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.geoapify-autocomplete-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    padding: 8px 12px;
}
