/* Sidebar */

#sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-left: 1px #fff solid;
  z-index: 1000;
  -webkit-transition: width 0.3s, padding 0.3s;
  transition: width 0.3s, padding 0.3s;
  width: 80px !important;
}

#sidebar.closed {
  width: 0 !important;
  padding: 0 !important;
}

#sidebar .toggle {
  position: absolute;
  top: 105px;
  width: 50px;
  height: 50px;
  background-color: rgba(108, 117, 125, 0.5);
  right: 100%;
  border: 1px #fff solid;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  display: flex !important;
  align-items: center !important;
}

#sidebar .bar {
  height: 100%;
  overflow: hidden;
}

#sidebar .likes-button {
  cursor: pointer;
}

#sidebar .links {
  height: 90%;
  padding: 10px;
  width: 80px;
  align-items: center !important;
  flex-direction: column !important;
  display: flex;
  background-color: #3e566d40;
  color: #fff;
  text-align: center;
}

#sidebar .lang {
  height: 10%;
  background-color: #fff;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

#sidebar .lang .form-control {
  border: none;
  padding: 0;
  width: auto;
  height: auto;
}

.fa-whatsapp::before {
  content: '\232';
}