#titleBar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 1999;
  padding-bottom: 75px;
  -webkit-box-shadow: inset 0px 111px 53px -28px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: inset 0px 111px 53px -28px rgba(0, 0, 0, 0.55);
  box-shadow: inset 0px 111px 53px -28px rgba(0, 0, 0, 0.55);
}

#titleBar h4 {
  font-size: 1.5rem !important;
}

.visibble-logo {
  width: auto;
  max-width: 150px;
  height: auto;
}

@media screen and (max-width: 860px) {
  .visibble-logo {
    max-width: 130px;
  }
  #titleBar {
    flex-direction: column;
  }
  .details-project {
    font-size: 1rem;
  }
  #titleBar h4 {
    font-size: 1.4rem !important;
    margin-top: unset !important;
  }
  .medium {
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 100% !important;
  }
}