.toolbar-gallery {
    margin: 15px;
}

.carousel-test {
    background-color: #39C0BA;
    border: 1px solid white;
    width: 50px;
    height: 55px;
    display: flex;
    justify-content: center;
    border-radius: 0.25rem;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    align-self: center;
}

.carousel-indicators li {
    height: 5px !important;
    width: 40px !important;
}
.carousel-control-next, .carousel-control-prev{
    opacity: 1 !important;
}

@media screen and (max-width: 520px) {
    .carousel-test {

        width: 40px;
        height: 45px;

    }
}