.contact.form-control {
  color: black !important;
  background-color: white !important;
  border-radius: 0px !important;
  border: 1px #fff solid !important;
  padding: 0.75rem !important;
  height: auto  !important;
}

.contact.form-control::placeholder {
  color: black !important;
}

.contact.btn-block {
  padding: 0.75rem !important;
  border-radius: 0px !important;
  font-weight: 700 !important;
}

.contact.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-size: 12px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0px !important;
  background-color: transparent;
  border: #fff solid 1px;
}

.custom-checkbox .custom-control-label::after {
  /* border-radius: .25rem; */
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  /* border-color: #007bff;
  background-color: #007bff; */
}
.phonemobile {
  color: black;
  font-size: 1.25rem;
  font-weight: 500;
}

.phonemobile:hover {
  color: black;
}