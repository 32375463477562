@import url("https://fonts.googleapis.com/css2?family=Bad+Script&display=swap");

/* Circle Icons */
.circle-icon {
  background-color: rgba(108, 117, 125, 0.1);
  width: 54px;
  height: 54px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
}

.circle-icon .far,
.circle-icon .fas {
  margin-left: auto !important;
  margin-right: auto !important;
}

.fade-enter-done {
  margin-top: 25px;
}
.arrayInput-product .button-add {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

form div,
input,
select,
textarea {
  background-color: white !important;
}
button:focus,
input:focus,
div:focus,
p:focus {
  outline: none !important;
}

#panel1a-header {
  min-height: 80px;
}

.button-3D > span > svg {
  display: none;
}

.button-3D {
  min-width: 100px;
}

.MuiFilledInput-root.Mui-focused {
  background-color: white;
}

.small-screen {
  margin-left: auto;
  display: flex;
}

#crisp-chatbox {
  z-index: 1299 !important;
}

@media screen and (max-width: 900px) {
  .small-screen {
    display: flex;
    flex-direction: column;
    margin-left: unset;
  }
  .delete-btn {
    border-radius: 20px !important;
  }
}

.previews {
  display: flex;
  flex-wrap: wrap;
}
.previews img {
  max-width: 300px;
}

.modal .modal-full-height {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 450px;
  width: 100%;
  height: auto;
  max-height: 100vh;
  min-height: 100vh;
  margin: 0;
  overflow: hidden;
}

.modal .modal-full-height.modal-more-width {
  max-width: 550px;
}

.modal-open .modal {
  z-index: 1041 !important;
}

.modal .modal-full-height::-webkit-scrollbar {
  width: 13px !important;
  height: 13px !important;
}
.modal .modal-full-height::-webkit-scrollbar-thumb {
  background: #ffffff !important;
  border-radius: 0px !important;
}
.modal .modal-full-height::-webkit-scrollbar-thumb:hover {
  background: #ffffff !important;
}
.modal .modal-full-height::-webkit-scrollbar-track {
  background: #ffffff !important;
  border-radius: 0px !important;
  box-shadow: inset 0px 0px 0px 0px #ffffff !important;
}

.modal .modal-content {
  background-color: rgb(255 255 255 / 95%) !important;
}
.closeSideModal {
  border-radius: 50%;
  height: 34px;
  width: 34px;
  padding-bottom: 2px !important;
  position: absolute;
  top: 35px;
  bottom: 0;
  left: -17px;
  right: 0;
  background-color: white !important;
  opacity: 1 !important;
  font-size: 16px;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-fullscreen {
  width: 100vw !important;
  max-width: none !important;
  height: 100%;
  margin: 0 !important;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-body {
  max-height: 90vh;
  overflow: auto;
}
