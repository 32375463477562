.input-range__track--active {
    background: #39C0BA;
}
.input-range__slider {
    background: #39C0BA;
    border: 1px solid #39C0BA;
}
.input-range__label--min, .input-range__label--max {
    display: none;
}
.input-range {
    width: 90%;
    margin: auto;
}