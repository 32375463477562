@import url(https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellefair&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bad+Script&display=swap);

* {
  box-sizing: border-box;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

html,
body, 
#root,
.App {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
  background: #f2f2f2 !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span, p {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif !important;
}

/** LOGIN / SIGNUP **/
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 500px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #2fbcee !important;
  border-color: #2fbcee !important;
  color: white;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

#__react-alert__ div div div {
  width: auto !important;
}

@media screen and (max-width: 900px) {
	.MuiBox-root {
		flex-direction: column;
	}
}
.modal-light .share-item {
  cursor: pointer;
}

.modal-light .share-item:hover {
  background-color: #DAE0E5 !important;
}

.MuiDialog-paper {
	padding: 1rem;
}
.input-range__track--active {
    background: #39C0BA;
}
.input-range__slider {
    background: #39C0BA;
    border: 1px solid #39C0BA;
}
.input-range__label--min, .input-range__label--max {
    display: none;
}
.input-range {
    width: 90%;
    margin: auto;
}
.geocoder-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.geoapify-autocomplete-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-animation-name: mui-auto-fill-cancel;
            animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    padding: 8px 12px;
}

.content--pourcentage {
    font-size: 20px;
}

.color-down {
    color: #ef8454;
}
.color-up {
    color: #bcd151;
}

.content--icon {
    padding-right: .5rem;
}


.mandat--title {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500;
}

.small-title {
    color: #a29e9e;
    margin-bottom: 0;
    font-weight: lighter;
}

.content--poucentage-container {
    transition: all 0.6s ease-in-out;
}
@media print {
  .RaLayout-appFrame-2 {
    margin-top: 0px;
  }

  .MuiDrawer-docked {
    display: none;
  }

  /* .MuiToolbar-root {
    display: none!important;
  } */
}

/* Circle Icons */
.circle-icon {
  background-color: rgba(108, 117, 125, 0.1);
  width: 54px;
  height: 54px;
  border: 1px solid #fff;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
}

.circle-icon .far,
.circle-icon .fas {
  margin-left: auto !important;
  margin-right: auto !important;
}

.fade-enter-done {
  margin-top: 25px;
}
.arrayInput-product .button-add {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

form div,
input,
select,
textarea {
  background-color: white !important;
}
button:focus,
input:focus,
div:focus,
p:focus {
  outline: none !important;
}

#panel1a-header {
  min-height: 80px;
}

.button-3D > span > svg {
  display: none;
}

.button-3D {
  min-width: 100px;
}

.MuiFilledInput-root.Mui-focused {
  background-color: white;
}

.small-screen {
  margin-left: auto;
  display: flex;
}

#crisp-chatbox {
  z-index: 1299 !important;
}

@media screen and (max-width: 900px) {
  .small-screen {
    display: flex;
    flex-direction: column;
    margin-left: unset;
  }
  .delete-btn {
    border-radius: 20px !important;
  }
}

.previews {
  display: flex;
  flex-wrap: wrap;
}
.previews img {
  max-width: 300px;
}

.modal .modal-full-height {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  max-width: 450px;
  width: 100%;
  height: auto;
  max-height: 100vh;
  min-height: 100vh;
  margin: 0;
  overflow: hidden;
}

.modal .modal-full-height.modal-more-width {
  max-width: 550px;
}

.modal-open .modal {
  z-index: 1041 !important;
}

.modal .modal-full-height::-webkit-scrollbar {
  width: 13px !important;
  height: 13px !important;
}
.modal .modal-full-height::-webkit-scrollbar-thumb {
  background: #ffffff !important;
  border-radius: 0px !important;
}
.modal .modal-full-height::-webkit-scrollbar-thumb:hover {
  background: #ffffff !important;
}
.modal .modal-full-height::-webkit-scrollbar-track {
  background: #ffffff !important;
  border-radius: 0px !important;
  box-shadow: inset 0px 0px 0px 0px #ffffff !important;
}

.modal .modal-content {
  background-color: rgb(255 255 255 / 95%) !important;
}
.closeSideModal {
  border-radius: 50%;
  height: 34px;
  width: 34px;
  padding-bottom: 2px !important;
  position: absolute;
  top: 35px;
  bottom: 0;
  left: -17px;
  right: 0;
  background-color: white !important;
  opacity: 1 !important;
  font-size: 16px;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-fullscreen {
  width: 100vw !important;
  max-width: none !important;
  height: 100%;
  margin: 0 !important;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-body {
  max-height: 90vh;
  overflow: auto;
}

#contact-form h2 {
  font-size: 1.5rem !important;
}

@media (min-width: 768px) {
  #contact-form h2 {
    font-size: 2rem !important;
  }
}

.contact.form-control {
  color: black !important;
  background-color: white !important;
  border-radius: 0px !important;
  border: 1px #fff solid !important;
  padding: 0.75rem !important;
  height: auto  !important;
}

.contact.form-control::-webkit-input-placeholder {
  color: black !important;
}

.contact.form-control::placeholder {
  color: black !important;
}

.contact.btn-block {
  padding: 0.75rem !important;
  border-radius: 0px !important;
  font-weight: 700 !important;
}

.contact.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-size: 12px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0px !important;
  background-color: transparent;
  border: #fff solid 1px;
}

.custom-checkbox .custom-control-label::after {
  /* border-radius: .25rem; */
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  /* border-color: #007bff;
  background-color: #007bff; */
}
.phonemobile {
  color: black;
  font-size: 1.25rem;
  font-weight: 500;
}

.phonemobile:hover {
  color: black;
}
#titleBar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 1999;
  padding-bottom: 75px;
  box-shadow: inset 0px 111px 53px -28px rgba(0, 0, 0, 0.55);
}

#titleBar h4 {
  font-size: 1.5rem !important;
}

.visibble-logo {
  width: auto;
  max-width: 150px;
  height: auto;
}

@media screen and (max-width: 860px) {
  .visibble-logo {
    max-width: 130px;
  }
  #titleBar {
    flex-direction: column;
  }
  .details-project {
    font-size: 1rem;
  }
  #titleBar h4 {
    font-size: 1.4rem !important;
    margin-top: unset !important;
  }
  .medium {
    justify-content: center;
    align-items: center;
    display: flex;
    max-width: 100% !important;
  }
}
/* Sidebar */

#sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-left: 1px #fff solid;
  z-index: 1000;
  transition: width 0.3s, padding 0.3s;
  width: 80px !important;
}

#sidebar.closed {
  width: 0 !important;
  padding: 0 !important;
}

#sidebar .toggle {
  position: absolute;
  top: 105px;
  width: 50px;
  height: 50px;
  background-color: rgba(108, 117, 125, 0.5);
  right: 100%;
  border: 1px #fff solid;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  display: flex !important;
  align-items: center !important;
}

#sidebar .bar {
  height: 100%;
  overflow: hidden;
}

#sidebar .likes-button {
  cursor: pointer;
}

#sidebar .links {
  height: 90%;
  padding: 10px;
  width: 80px;
  align-items: center !important;
  flex-direction: column !important;
  display: flex;
  background-color: #3e566d40;
  color: #fff;
  text-align: center;
}

#sidebar .lang {
  height: 10%;
  background-color: #fff;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

#sidebar .lang .form-control {
  border: none;
  padding: 0;
  width: auto;
  height: auto;
}

.fa-whatsapp::before {
  content: '\232';
}
a:hover {
  text-decoration: none !important;
}

.toolbar-gallery {
    margin: 15px;
}

.carousel-test {
    background-color: #39C0BA;
    border: 1px solid white;
    width: 50px;
    height: 55px;
    display: flex;
    justify-content: center;
    border-radius: 0.25rem;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    align-self: center;
}

.carousel-indicators li {
    height: 5px !important;
    width: 40px !important;
}
.carousel-control-next, .carousel-control-prev{
    opacity: 1 !important;
}

@media screen and (max-width: 520px) {
    .carousel-test {

        width: 40px;
        height: 45px;

    }
}
.toolbar-gallery {
    margin: 15px;
}
.carousel-control {
    background-color: #39C0BA;
    border: 1px solid white;
    width: 50px !important;
    height: 55px !important;
    display: flex;
    justify-content: center;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    align-self: center;
}

.carousel-indicators li {
    height: 5px !important;
    width: 40px !important;
}
video:focus {
    outline: none;
}

.carousel-control-prev {
    max-height: 85%;
    margin-top: 25px;
}
.carousel-control-next {
    max-height: 85%;
    margin-top: 25px;
}

.carousel-control-next, .carousel-control-prev{
    opacity: 1 !important;
}
#modal-share .share-item {
  cursor: pointer;
}

#modal-share .share-item:hover {
  background-color: #DAE0E5 !important;
}

a:hover {
  text-decoration: none !important;
}

