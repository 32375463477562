@media print {
  .RaLayout-appFrame-2 {
    margin-top: 0px;
  }

  .MuiDrawer-docked {
    display: none;
  }

  /* .MuiToolbar-root {
    display: none!important;
  } */
}
