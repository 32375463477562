.content--pourcentage {
    font-size: 20px;
}

.color-down {
    color: #ef8454;
}
.color-up {
    color: #bcd151;
}

.content--icon {
    padding-right: .5rem;
}


.mandat--title {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500;
}

.small-title {
    color: #a29e9e;
    margin-bottom: 0;
    font-weight: lighter;
}

.content--poucentage-container {
    transition: all 0.6s ease-in-out;
}