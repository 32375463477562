.toolbar-gallery {
    margin: 15px;
}
.carousel-control {
    background-color: #39C0BA;
    border: 1px solid white;
    width: 50px !important;
    height: 55px !important;
    display: flex;
    justify-content: center;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    align-self: center;
}

.carousel-indicators li {
    height: 5px !important;
    width: 40px !important;
}
video:focus {
    outline: none;
}

.carousel-control-prev {
    max-height: 85%;
    margin-top: 25px;
}
.carousel-control-next {
    max-height: 85%;
    margin-top: 25px;
}

.carousel-control-next, .carousel-control-prev{
    opacity: 1 !important;
}